import React, { Component } from "react"
import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Footer from "../components/footer"
import { Link, graphql } from "gatsby"
import ReactPlayer from "react-player"

export const query = graphql`
  query($slug: String!) {
    datoCmsPublicacion(slug: { eq: $slug }) {
      titulo
      contenido
      slug
      fotoOIlustracion {
        url
      }
      documentoPdf {
        url
      }
      youtubeVideo {
        url
      }
    }
  }
`

export const PdfLink = ({ url }) => {
  console.log(url)
  return (
    <a href={url} target="_blank">
      Descargar el PDF
    </a>
  )
}

export const Youtube = ({ url }) => {
  return (
    <div
      className="player-wrapper"
      style={{ position: `relative`, paddingTop: `56.25` }}
    >
      <ReactPlayer
        className="react-player"
        url={url}
        width="100%"
        height="100%"
        style={{ position: `absolute`, top: `0`, left: `0` }}
      />
    </div>
  )
}

const Content = ({ data }) => {
  const contentenido = data.datoCmsPublicacion

  if (contentenido.documentoPdf === null) {
    contentenido.documentoPdf = ""
  }
  if (contentenido.youtubeVideo === null) {
    contentenido.youtubeVideo = ""
  }

  return (
    <Layout pageTitle={contentenido.titulo}>
      <div className="w-full mx-auto  bg-gray-200 border-b border-gray-400">
        <div className="w-full lg:w-10/12 mx-auto">
          <Navigation />
        </div>
      </div>
      <div className="w-full px-5 lg:px-0 lg:w-10/12 mx-auto">
        <div className="w-full mx-auto mt-10 lg:mt-20">
          <Link to="/publicaciones" className="text-sm mb-5 block underline">
            Ver todas las publicaciones
          </Link>
          <div className="w-full lg:flex">
            <div className="w-full lg:w-1/3">
              <img
                src={contentenido.fotoOIlustracion.url}
                alt={contentenido.titulo}
                className="border border-gray-200 p-3 mt-3 shadow-md"
              />
            </div>
            <div className="w-full lg:2/3">
              <h2 className="text-3xl mt-10 lg:mt-0">{contentenido.titulo}</h2>

              <div className="w-full">
                <div
                  className="home-body"
                  dangerouslySetInnerHTML={{
                    __html: contentenido.contenido
                  }}
                ></div>

                <div className="home-body mt-10">
                  {contentenido.documentoPdf ? (
                    <PdfLink url={contentenido.documentoPdf.url} />
                  ) : (
                    ``
                  )}

                  {contentenido.youtubeVideo ? (
                    <Youtube
                      url={contentenido.youtubeVideo.url}
                      style={{ marginTop: "30px" }}
                    />
                  ) : (
                    ``
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Content
